.muiPagination {
  .MuiPagination-root {
    .MuiPagination-ul {
      li {
        button {
          overflow: hidden;
          color: black;
          border-radius: 5px;
          font-weight: 500;
          border: 1px solid #CECECE;
          background-color: white;
        }

        .css-8je8zh-MuiTouchRipple-root {
          border-radius: 0;
          width: 100%;
          height: 100%;
        }

        .MuiPaginationItem-ellipsis {
          font-weight: 600;
          width: 32px;
          height: 32px;
          border: 1px solid #CECECE;
          border-radius: 0;
        }

        .Mui-selected {
          border: 1px solid #094823;
          background: #094823;
          color: white;
        }
      }
    }
  }
}