.pageEditor {
  position: relative;
  &-header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10002;
    background: #ffffff;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.04);
    padding: 10px 15px;
    .icon {
      width: 60px;
      height: auto;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    &-buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 10px;
      .part2 {
        display: flex;
        gap: 10px;
      }
    }
    .status {
      min-width: 100%;
      padding-top: 10px;
    }
  }
  &-body {
    background-color: #f5f5f5;
    min-height: 100vh;
    &-content {
      margin: 0 auto;
      // padding: 25px 90px 15px 15px;
      .editor {
        background-color: white;
      }
    }
  }
}
@media screen and (min-width: 600px) {
  .pageEditor {
    &-header {
      padding: 10px 30px;
    }
    &-body {
      &-content {
        // padding: 30px 110px 30px 30px;
      }
    }
  }
}
@media screen and (min-width: 960px) {
  .pageEditor {
    &-header {
      .icon {
        width: 65px;
        height: auto;
      }
    }
  }
}
