.dashboardNavbar {
  overflow: hidden;
  &-logo {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100px;
      height: auto;
    }
  }

  &-navlinks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 10px;
  }

  &-navlink {
    text-decoration: none;
    color: #a5a5a5;
    font-size: 13px;
    width: 100%;

    &:hover {
      color: #045428;
    }

    .active {
      border-left: 4px solid #FEED24;
      border-radius: 4px;
      font-weight: 300;

      color: #045428;
      background-color: rgba(93, 140, 32, 0.03);
    }

    .item {
      padding: 8px 0 8px 25px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;

      h4 {
        margin-left: 15px;
        color: #707070;
        font-weight: 300;
      }
    }

    &.active {
      border-left: 4px solid #FEED24;
      border-radius: 4px;
      transition: 200ms;

      .item {
        color: #045428;
        background-color: rgba(93, 140, 32, 0.03);
      }
    }
  }

  &-navlinkChild {
    text-decoration: none;
    color: #a5a5a5;
    width: 100%;

    .active {
      color: #045428;
    }

    .itemChild {
      padding: 10px 0 10px 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      color: #a5a5a5;
      gap: 4px;

      &:hover {
        color: #045428;
      }
    }

    &.active {
      .itemChild {
        padding-left: 40px;
        color: #045428;


        h5 {
          color: #045428;
        }
      }
    }
  }

  &-logout {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border: none;
    background-color: white;
    color: #a5a5a5;
    padding: 10px 0 10px 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-start;

    h4 {
      font-size: 14px;
      margin-left: 15px;
      color: #707070;
      font-weight: 300;
    }
  }

  &-logout:hover {
    color: #045428;
    background-color: rgba(93, 140, 32, 0.03);

    h4 {
      color: #045428;
    }
  }
}