.contactFollowOrganisms {
  padding: 20px;
  > * {
    margin-bottom: 10px !important;
  }
  .firstInputs {
    display: flex;
    gap: 10px;
  }
  &-buttons {
    margin-top: 10px;
  }
  &-status {
    margin-top: 10px;
  }
}
@media screen and (min-width: 600px) {
  .contactFollowOrganisms {
    > * {
      margin-bottom: 15px !important;
    }
    .firstInputs {
      gap: 15px;
    }
  }
}
@media screen and (min-width: 960px) {
  .contactFollowOrganisms {
    > * {
      margin-bottom: 20px !important;
    }
    .firstInputs {
      gap: 15px;
    }
  }
}
