.patroMagazines{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    row-gap: 15px;
    >*{
        grid-column: span 12;
    }
}
@media screen and (min-width: 600px) {
    .patroMagazines{
        gap: 15px;
        >*{
            grid-column: span 6;
        }
    }
}
@media screen and (min-width: 1200px) {
    .patroMagazines{
        gap: 30px;
        >*{
            grid-column: span 4;
        }
    }
}
@media screen and (min-width: 1200px) {
    .patroMagazines{
        gap: 40px;
        >*{
            grid-column: span 4;
        }
    }
}