.cover{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 15px;
    >*{
        background-color: white;
        padding: 20px 25px;
        border-radius: 5px;
        h4{
            color: #626262;
            font-size: 14px;
            font-weight: 300;
        }
        p{
            color:#9A9A9A;
            font-size: 12px;
        }
    }
    .magazineCover{
        grid-column: span 12;
        display: flex;
        flex-direction: column;
        h3{
            color: #626262;
            margin-bottom: 5px;
            font-weight: 300;
        }
        p{
            color:#9A9A9A;
            margin-bottom: 15px;
        }
        .pError{
            font-size: 12px;
            color: rgb(200, 0, 0);
            font-weight: 300;
        }
        .coverImg{
            align-self: center;
            border-radius: 5px;
            position: relative;
            width: 250px;
            height:calc( 250px * 29.7/21);
            img{
                object-fit: cover;
                object-position: center;
                bottom: 5px;
                border-radius: 5px;
                width: 250px;
                height:calc( 250px * 29.7/21);
                border: 1px solid rgba(0, 0, 0, 0.1);
            }
            input[type="file"] {
                display: none;
            }
            .divWithPopover{
                position: relative;
            }
            .changeCoverIcon{
                position: absolute;
                bottom: 9px;
                right: 7px;
            }
            .changingCoverImgLoader{
                position: absolute;
                top: 50%;
                right: 105px;
            }
            .saveAndRemoveIcons{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: absolute;
                bottom: 9px;
                right: 7px;
                button{
                    display: flex;
                    align-items: center;
                    padding: 0;
                    background-color: transparent;
                    border: none;
                }
            }
        }
        
    }
    .magazineStatus{
        grid-column: span 12;
        max-height: 120px;
        .status, .published{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .dot{
                width: 10px;
                height: 10px;
                border-radius: 500px;
                margin-right: 12px;
            }
            .publishtMagazine{
                background-color: rgb(124, 179, 151);
            }
            .unPublishtMagazine{
                background-color: rgba(216, 222, 219)
            }
        }
        p{
            margin-bottom: 15px;
        }
        :first-child{
            h4{
            margin-bottom: 3px;
            }
        }
        :last-child{
            margin-bottom: 0px;
        }
    }
}
.tab{
    &-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom:20px;
        &-title{
            h3{
                font-size: 14px;
                font-weight: 300;
                margin-bottom: 8px;
            }
            p{
                color: #9A9A9A;
                font-size: 12px;
            }
        }
        &-button{
            border: none;
            background-color: transparent;
            padding: 4px 8px;
            color: #045428;
            font-size: 12px;
            border-radius: 5px;
            &:hover{
                background-color: rgba(4, 84, 40, 0.1);
            }
        }
    }
    &-buttons{
        margin-top: 70px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    }
    &-status{
        margin-top: 15px;
    }
    &-body{
        >*{
            margin-bottom: 10px;
        }
        :last-child{
            margin-bottom: 0;
        }
        .article{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 15px;
            border: 1px solid #D9D8D9;
            border-radius: 5px;
        }
        .article-pages{
            padding: 8px 15px;
            border: 1px solid #D9D8D9;
            border-radius: 5px;
            ul{
                display: flex;
                flex-direction: column;
                gap: 10px;
                margin-bottom: 15px;
                li{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    p{
                        color: #707070;
                        font-size: 14px;
                        font-weight: 300;
                    }
                }
            }
            &-button{
                border: 0.5px dashed #045428;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 300;
                width: 100%;
                background-color: #E9EEEB;
                color: #045428;
                padding: 8px 16px;
            }
        }
        .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before{
            height: 0;
        }
    }
}
@media screen and (min-width: 640px) {
    .cover{
        .magazineCover{
            min-width: 300px;
            width: 100%;
            grid-column: auto;
        }
        .magazineStatus{
            grid-column: 2/13;
        }
    }
}
@media screen and (min-width: 1100px) {
    .cover{
        .magazineCover{
            grid-column: span 12;
            justify-self: center;
        }
        .magazineStatus{
            grid-column: span 12;
        }
    }
}