.addPageTitle {
    .addMagazinePage {
        &-content {
            &-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 15px;
                p {
                    color: #000;
                    font-size: 14px;
                    font-family: Poppins;
                    font-weight: 500;
                    text-transform: capitalize;
                }
            }
            &-templates {
                height: 300px;
                overflow-y: scroll;
                padding: 10px 5px;
                margin: 15px 0;
                display: grid;
                grid-template-columns: repeat(1, 200px);
                gap: 15px;
                justify-content: center;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
                &::-webkit-scrollbar {
                    display: none;
                }
                .templateItem {
                    width: 100%;
                    height: 200px;
                    border-radius: 5px;
                    background: #fff;
                    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.06);
                    position: relative;
                    overflow: hidden;
                    iframe {
                        transform-origin: 0 0;
                        transform: scale(0.5);
                        width: 200%;
                        height: 200%;
                    }
                    p {
                        width: 100%;
                        background-color: white;
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        text-align: center;
                        box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.06);
                        padding: 10px;
                    }
                    &:hover {
                        cursor: pointer;
                        outline: 2px solid #69c090;
                    }
                }
                .blankItem {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f3f7f4;
                }
                .selected {
                    outline: 2px solid #045428;
                }
            }
        }
    }
}
@media screen and (min-width: 600px) {
    .addPageTitle {
        .addMagazinePage {
            &-content {
                &-templates {
                    grid-template-columns: repeat(2, 200px);
                    gap: 10px;
                }
            }
        }
    }
}
@media screen and (min-width: 1200px) {
    .addPageTitle {
        .addMagazinePage {
            &-content {
                &-templates {
                    grid-template-columns: repeat(5, 200px);
                    gap: 20px;
                }
            }
        }
    }
}
