.createMagazine {
  background-color: white;
  border-radius: 5px;
  padding: 15px 20px;

  &-header {
    margin-bottom: 20px;

    h3 {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    p {
      color: #9a9a9a;
      font-size: 12px;
      font-weight: 300;
    }
  }

  .createMagazine-content .inputs-part1 .uploadMagazineCover:hover {
    background-color: #deecfc;
    cursor: pointer;
  }

  .createMagazine-content .inputs-part1 .uploadMagazineCover:active {
    background-color: #edf4fc;
  }

  &-content {
    .inputs {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 20px;


      &-part1 {
        grid-column: span 12;

        input[type='file'] {
          display: none;
        }

        display: flex;
        justify-content: center;


        .uploadMagazineCover {
          .noPhoto {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h4 {
              font-size: 12px;
              margin-bottom: 10px;

              span {
                color: #1976d2;
                font-weight: 300;
              }
            }

            p {
              font-size: 10px;
              color: #9a9a9a;
            }
          }

          background-color: #edf4fc;


          border: 2px dashed #1976d238;
          border-radius: 10px;
          width: 250px;
          height: calc(250px * 29.7 / 21);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .errorNoFile {
          background-color: rgba(255, 0, 0, 0.042);
          border: 1.5px dashed rgb(200, 0, 0) !important;

          .noPhoto {
            h4 {
              span {
                color: rgb(200, 0, 0) !important;
              }
            }

            .pError {
              font-size: 12px;
              margin-top: 10px;
              color: rgb(200, 0, 0);
              font-weight: 300;
            }
          }
        }

        .magazineCover {
          height: calc(250px * 29.7 / 21);
          width: 250px;
          position: relative;

          img {
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            width: 250px;
            height: calc(250px * 29.7 / 21);
            object-fit: cover;
            object-position: center;
          }

          .icon {
            position: absolute;
            top: -12px;
            right: -12px;
          }
        }
      }

      &-part2 {
        grid-column: span 12;
        text-align: start;

        .magazineTypes {
          display: flex;
          align-items: center;
          gap: 10px;

          input {
            height: 12px;
          }

          label {
            font-size: 13px;
            color: #9a9a9a;
          }
        }

        > * {
          margin-bottom: 12px;
        }
      }
    }

    &-buttons {
      margin-top: 40px;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 10px;
    }
  }
}

@media screen and (min-width: 450px) {
  .createMagazine {
    padding: 20px 35px;

    &-header {
      margin-bottom: 25px;
    }

    &-content {
      .inputs {
        &-part1 {
          grid-column: span 12;
          justify-content: center;
        }

        &-part2 {
          grid-column: span 12;
        }
      }

      &-buttons {
        margin-top: 45px;
        gap: 10px;
      }
    }
  }
}

@media screen and (min-width: 680px) {
  .createMagazine {
    padding: 30px 40px;

    &-header {
      margin-bottom: 30px;
    }

    &-content {
      .inputs {
        gap: 25px;

        &-part1 {
          justify-content: flex-start;
          grid-column: auto;
        }

        &-part2 {
          text-align: start;
          grid-column: span 11;

          > * {
            margin-bottom: 15px;
          }
        }
      }

      &-buttons {
        margin-top: 50px;
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  .createMagazine {
    padding: 50px 80px;

    &-header {
      margin-bottom: 40px;
    }

    &-content {
      .inputs {
        gap: 40px;
      }

      &-buttons {
        margin-top: 60px;
      }
    }
  }
}

.uploadmagazine {
  border: 1px dashed rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
