tr:hover {
  background-color: #fafafa;
}

.listItem {
  td:hover {
    cursor: pointer;
  }

  height: 100%;
  &-filesNumber {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;

    img {
      height: 32px;
    }

    &-content {
      color: #707070;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      gap: 3px;

      h4 {
        font-weight: 500;
      }

      p {
        font-weight: 300;
      }
    }
  }

  .fileEditor {
    justify-content: start;
  }

  &-role {
    margin: 0 auto;
    min-width: 120px;
    max-width: max-content;
    padding: 5px 10px;
    border-radius: 12px;
    background-color: rgba(13, 98, 31, 0.02);
  }

  &-icons {
    > * {
      margin-right: 5px;
    }

    :last-child {
      margin-right: 0;
    }
  }
}

@media screen and (min-width: 900px) {
  .listItem {
    &-icons {
      > * {
        margin-right: 15px;
      }

      :last-child {
        margin-right: 0;
      }
    }
  }
}