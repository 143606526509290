.loginPassswordLayout{
    width: 100vw;
    min-height: 100vh;
    background-image: url(../../assets/images/loginPasswordBg.png);
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    &-mainLogo{
        position: absolute;
        top:10px;
        left: 15px;
        width: 90px;
        height: auto;
    }
    &-content{
        background-color: white;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        padding: 35px 15px 20px 15px;
        width: 100%;
        max-width: 390px;
        margin-top: 150px;
    }
}
@media screen and (min-width: 680px){
    .loginPassswordLayout{
        align-items: center;
        &-mainLogo{
            top:15px;
            left: 20px;
        }
        &-content{
            padding: 40px 20px 25px 20px;
            max-width: 400px;
            margin: 0;
        }
    }
}
@media screen and (min-width: 960px){
    .loginPassswordLayout{
        &-mainLogo{
            top:20px;
            left: 25px;
        }
        &-content{
            padding: 45px 25px 30px 25px;
            max-width: 420px;
        } 
    }

}
@media screen and (min-width: 1280px){
    .loginPassswordLayout{
        &-mainLogo{
            top:25px;
            left: 30px;
        }
        &-content{
            padding: 50px 30px 35px 30px;
            max-width: 440px;
        }
    }
}