.userQuiz{
    position: relative;
    &-header{
        position: relative;
        display: flex;
        align-items: start;
        justify-content: space-between;
        gap: 15px;
        .title{
            align-self: center;
            h4{
                font-size: 22px;
                font-weight: 600;
                color: #3EB5B7;
                text-shadow: 1px 1px #000000;
                margin-bottom: 20px;
            }
            p{
                font-size: 16px;
                font-weight: 300;
            }
        }
        img{
            z-index: 2;
            width: auto;
            height: 110px;
            margin-bottom: -10px;
        }
    }
    &-body{
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
        .input{
            background-color: #ffffff;
            border-radius: 5px;
            border: 1px solid #C3C3C3;
            overflow: hidden;
        }
        .input label span {
            padding: 10px;
            display: block;
        }
        .input label input {
            position: absolute;
            display: none;
            color: #fff !important;
        }
        /* selects all of the text within the input element and changes the color of the text */
        .input label input + span{color: black;}
        .input input:checked + span {
            color: darken(#C3C3C3, 50%);
            font-weight: 300;
        }
        .input input:checked + span{background-color: rgba(195, 195, 195, 0.3);}
        .true{
            border: 1px solid  lighten(green, 15%) !important;
            background-color: lighten(green, 70%) !important;
            color: lighten(green, 15%) !important;
        }
        .false{
            border: 1px solid  lighten(red, 15%) !important;
            background-color: lighten(red, 48%) !important;
            color: lighten(red, 15%)!important;
        }
    }
    &-footer{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
    }
}
@media screen and (min-width: 600px) {
    .userQuiz{
        &-body{
            margin-bottom: 25px;
            .input label span {
            padding: 12px;
            }
        }
    }
}
@media screen and (min-width: 1200px) {
    .userQuiz{
        &-body{
            margin-bottom: 30px;
            .input label span {
            padding: 14px;
            }
        }
    }
}