.homeSection1 {
  padding-top: 77.75px;
  background-size: cover;
  background-position: top center;
  background-position-y: 77.75px;
  -webkit-transition: background-image 1s ease-in-out;
  -moz-transition: background-image 1s ease-in-out;
  -o-transition: background-image 1s ease-in-out;
  transition: background-image 1s ease-in-out;
  image-rendering: optimizeQuality;
  background-repeat: no-repeat;
  position: relative;

  &-content {
    padding: 50px 0 20px 0;

    &-part1 {
      margin-bottom: 100px;

      > * {
        margin-bottom: 5px;
      }

      h2 {
        color: #a4c408;
        font-size: 20px;
      }

      h1 {
        color: white;
        font-weight: 600;
        font-size: 30px;
        margin-bottom: 10px;
      }

      p {
        color: white;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .part2 {
      display: flex;
      align-items: center;
      justify-content: end;
    }

    &-part2 {
      z-index: 2;
      min-height: 180px;
      overflow-x: hidden;
      margin-left: 70px;
      background-color: #a4c408;
      border-radius: 30px 0 0 30px;
      padding: 15px 20px 15px 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;

      h4 {
        font-size: 18px;
        font-weight: 500;
        white-space: nowrap;
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
      }

      .cards {
        display: flex;
        align-items: center;
        gap: 15px;
        border-radius: 10px 0 0 10px;
        user-select: none;
        overflow-x: auto;
        padding: 4px 0 4px 0;

        img {
          pointer-events: none;
        }

        .card {
          color: black;
          text-decoration: none;
          min-width: 380px;
          display: flex;
          align-items: center;
          border-radius: 10px;
          gap: 10px;
          -webkit-transition: 0.15s ease-in-out;
          -moz-transition: 0.15s ease-in-out;
          -o-transition: 0.15s ease-in-out;
          transition: 0.15s ease-in-out;

          img {
            height: 120px;
            width: 180px;
            min-width: 180px;
            border-radius: 10px;
            object-fit: cover;
            object-position: center;
          }

          &-content {
            min-width: 150px;

            h5 {
              font-size: 20px;
              margin-bottom: 8px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              white-space: pre-wrap;
              -webkit-line-clamp: 1;
              line-height: 1.5;
            }

            p {
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              white-space: pre-wrap;
              -webkit-line-clamp: 2;
              font-size: 12px;
              margin-bottom: 20px;
              line-height: 1.5;
            }

            a {
              color: black;
              text-decoration: none;
              font-size: 12px;
              font-weight: 500;

              &:hover {
                color: #045428;
              }
            }
          }

          &:hover {
            -webkit-transform: translateY(-1px);
            -moz-transform: translateY(-1px);
            transform: translateY(-1px);
          }
        }

        &::-webkit-scrollbar {
          width: 6px;
          height: 4px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          background-color: #0C4B23;
        }

        &::-webkit-scrollbar-thumb:hover {
          cursor: pointer;
          height: 8px;
        }
      }
    }
  }

  .lg {
    position: absolute;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 12.17%, #ffffff 90.64%);
    width: 100%;
    height: 250px;
    bottom: 0px;
  }
}

.homeSection2 {
  padding: 8px 0 40px 0;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 0;

    h3 {
      font-weight: 600;
      font-size: 16px;
    }

    a {
      font-size: 12px;
      color: #9ac93b;
      text-decoration: none;
      font-weight: 300;

      &:hover {
        color: #045428;
        font-weight: 500;
      }
    }
  }
}

.slideBg-0 {
  background-image: url(../../assets/images/homeBg1.png);
}

.slideBg-1 {
  background-image: url(../../assets/images/homeBg2.png);
}

.slideBg-2 {
  background-image: url(../../assets/images/homeBg3.png);
}

@media screen and (min-width: 600px) {
  .homeSection1 {
    &-content {
      padding: 70px 0;

      &-part1 {
        width: 70%;
        margin-bottom: 110px;

        > * {
          margin-bottom: 8px;
        }

        h2 {
          font-size: 20px;
        }

        h1 {
          font-size: 30px;
          margin-bottom: 15px;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }
  .homeSection2 {
    padding: 10px 0 50px 0;

    &-header {
      padding: 20px 0;

      h3 {
        font-size: 18px;
      }

      a {
        font-size: 14px;
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .homeSection1 {
    padding-top: 110px;
    background-position-y: 0;

    &-content {
      padding: 80px 0;

      &-part1 {
        width: 60%;
        margin-bottom: 120px;

        h2 {
          font-size: 22px;
        }

        h1 {
          font-size: 32px;
        }
      }
    }
  }
  .homeSection2 {
    padding: 15px 0 60px 0;

    &-header {
      padding: 30px 0;

      h3 {
        font-size: 20px;
      }

      a {
        font-size: 16px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .homeSection1 {
    &-content {
      padding: 100px 0;

      &-part1 {
        width: 40%;
        margin-bottom: 50px;

        h2 {
          font-size: 20px;
        }

        h1 {
          font-size: 35px;
          line-height: 50px;
        }

        p {
          line-height: 25px;
        }
      }
    }
  }
  .homeSection2 {
    padding: 20px 0 80px 0;

    &-header {
      padding: 40px 0;

      h3 {
        font-size: 24px;
      }

      a {
        font-size: 18px;
      }
    }
  }
}
