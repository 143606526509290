.magazines {
  position: relative;
  padding-top: 77.75px;

  &-content {
    padding: 20px 0 30px 0;

    &-header {
      margin-bottom: 20px;

      h1 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 25px;
      }

      h3 {
        font-size: 16px;
        font-weight: 300;
        color: #045428;
      }
    }

    &-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
    }
  }
}

@media screen and (min-width: 600px) {
  .magazines {
    &-content {
      padding: 30px 0;

      &-header {
        margin-bottom: 25px;

        h1 {
          font-size: 22px;
          margin-bottom: 30px;
        }

        h3 {
          font-size: 18px;
        }
      }

      &-pagination {
        justify-content: flex-end;
        margin-top: 20px;
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .magazines {
    padding-top: 110px;

    &-content {
      padding: 35px 0;

      &-header {
        margin-bottom: 30px;

        h1 {
          font-size: 24px;
          margin-bottom: 35px;
        }

        h3 {
          font-size: 18px;
        }
      }

      &-pagination {
        margin-top: 30px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .magazines {
    &-content {
      padding: 40px 0 80px 0;

      &-header {
        margin-bottom: 35px;

        h1 {
          font-size: 25px;
          margin-bottom: 40px;
        }
      }

      &-pagination {
        margin-top: 40px;
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  .magazines {
    &-content {
      padding: 80px 0 100px 0;

      &-pagination {
        margin-top: 50px;
      }
    }
  }
}