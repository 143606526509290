@import "./dashboard/MediaFilePreview/MediaFilePreview";
@import "./dashboard/AddPageTitle/AddPageTitle";
.MuiModal-root {
  .MuiBox-root {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    canvas {
      max-width: 350px;
    }
    .quizInput {
      width: 100%;
      padding: 10px 15px;
      border-radius: 5px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      &:focus {
        outline: 2px solid #045428;
      }
      &:hover {
        outline: 2px solid #045428;
      }
    }
    .muiModal {
      background-color: rgb(255, 255, 255);
      width: 350px;
      padding: 15px 20px;
      border-radius: 10px;
      &-header {
        h3 {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 8px;
        }
        p {
          font-size: 14px;
          color: #9a9a9a;
        }
        margin-bottom: 15px;
      }
      &-content {
        &-inputs {
          &-input {
            margin-bottom: 10px;
          }
          .addFile {
            input[type="file"] {
              display: none;
            }
            background-color: lighten(#1976d2, 50%);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 24px 0;
            border-radius: 5px;
            border: 1px dashed #1976d2;
            &:hover {
              background-color: #deedfd;
            }
            &:active {
              background-color: #edf5fd;
            }
            .noFile {
              text-align: center;
              h4 {
                font-size: 12px;
                margin-bottom: 12px;
                span {
                  color: #1976d2;
                  font-weight: 300;
                }
              }
              p {
                font-size: 10px;
                color: #9a9a9a;
              }
            }
          }
          .pError {
            font-size: 12px;
            margin-top: 10px;
            margin-left: 12px;
            color: rgb(200, 0, 0);
          }
          .errorNoFile {
            background-color: rgba(255, 0, 0, 0.042);
            border: 1.5px dashed rgb(200, 0, 0) !important;
            .noFile {
              h4 {
                span {
                  color: rgb(200, 0, 0) !important;
                }
              }
              .pError {
                font-size: 12px;
                margin-top: 10px;
                color: rgb(200, 0, 0);
                font-weight: 300;
              }
            }
          }
          .quizTypes {
            display: flex;
            align-items: center;
            gap: 10px;
            input {
              height: 12px;
            }
            label {
              font-size: 13px;
              color: #9a9a9a;
            }
          }
          .addChoise {
            p {
              font-size: 12px;
              color: #9a9a9a;
              margin-bottom: 10px;
            }
            &-options {
              > * {
                margin-bottom: 10px;
              }
              &-option {
                color: black !important;
                font-weight: 300;
                padding: 10px;
                border-radius: 5px;
                border: 1.5px solid rgba(153, 153, 153, 0.8);
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                p {
                  margin-bottom: 0;
                  word-break: break-all;
                }
              }
              margin-bottom: 10px;
            }
            &-input {
              font-size: 12px;
              width: 100%;
              padding: 10px;
              background-color: white;
              border-radius: 5px;
              border: 1.5px dashed rgba(153, 153, 153, 0.8);
            }
          }
          &-addTags {
            display: flex;
            gap: 10px;
            button {
              white-space: nowrap;
              width: 120px;
              height: 36px;
              background-color: #edf3f0;
              border: none;
              border-radius: 5px;
              color: #045428;
              &:hover {
                background-color: darken(#edf3f0, 5%);
              }
            }
          }
          :last-child {
            margin-bottom: 0;
          }
        }
        .fileUpload {
          padding: 5px 2px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 5px;
          margin-top: 10px;
          border: 1px dashed #1976d2;
          border-radius: 5px;
          &-info {
            width: 100%;
            p {
              font-size: 14px;
              color: #003d7a;
              span {
                color: #9a9a9a;
                font-size: 12px;
              }
            }
          }
          :last-child {
            align-self: flex-start;
          }
        }

        &-tags {
          display: flex;
          gap: 10px;
          overflow-x: scroll;
          &-tag {
            font-size: 12px;
            padding: 5px 10px;
            display: flex;
            align-items: center;
            gap: 5px;
            background-color: #edf3f0;
            color: #045428;
            border-radius: 10px;
            white-space: nowrap;
          }
        }
        &-modalbuttons {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          margin-top: 15px;
        }
        &-status {
          margin-top: 15px;
        }
      }
    }
  }
}
@media screen and (min-width: 600px) {
  .MuiModal-root {
    .MuiBox-root {
      canvas {
        max-width: 400px;
      }
      .muiModal {
        width: 400px;
        padding: 20px 25px;
        &-header {
          h3 {
            margin-bottom: 10px;
          }
          margin-bottom: 20px;
        }
        &-content {
          &-inputs {
            &-addTags {
              button {
                height: 40px;
              }
            }
          }
          .fileUpload {
            gap: 8px;
            margin-top: 12px;
          }
          &-modalbuttons {
            gap: 10px;
            margin-top: 20px;
          }
          &-status {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 900px) {
  .MuiModal-root {
    .MuiBox-root {
      canvas {
        max-width: 500px;
      }
      .muiModal {
        width: 500px;
        padding: 25px 30px;
        &-header {
          h3 {
            margin-bottom: 12px;
          }
          margin-bottom: 24px;
        }
        .scrollbarShow {
          padding-bottom: 5px;
        }
        &-content {
          ::-webkit-scrollbar {
            padding-top: 10px;
            height: 4px;
          }
          ::-webkit-scrollbar-track {
            background: rgb(255, 255, 255);
            border-radius: 10px;
          }
          ::-webkit-scrollbar-thumb {
            background: #045428;
            border-radius: 10px;
          }
          .fileUpload {
            gap: 10px;
            margin-top: 15px;
          }
          &-inputs {
            &-addTags {
              button {
                height: 44px;
              }
            }
            &-input {
              margin-bottom: 12px;
            }
          }
          &-modalbuttons {
            gap: 12px;
            margin-top: 24px;
          }
          &-status {
            margin-top: 24px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .MuiModal-root {
    .MuiBox-root {
      canvas {
        max-width: 600px;
      }
      .muiModal {
        width: 600px;
        padding: 30px 35px;
        &-header {
          h3 {
            margin-bottom: 15px;
          }
          margin-bottom: 30px;
        }
        &-content {
          &-inputs {
            &-input {
              margin-bottom: 15px;
            }
          }
          &-modalbuttons {
            gap: 14px;
            margin-top: 30px;
          }
          &-status {
            margin-top: 30px;
          }
        }
      }
    }
  }
}
