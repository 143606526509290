.radioGroup {
  display: flex;
  justify-content: space-between;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
.continuebutton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .radioGroup {
    flex-direction: column;
    .item {
      margin-top: 10px;
    }
  }
}
