.MuiModal-root{
    .MuiBox-root{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .mediaFilePreviewModal{
            background-color: transparent;
            border-radius: 10px;
            position: relative;
            img, video{
                border: 3px solid rgba(126, 126, 126, 0.5);
                object-fit: cover;
                object-position: center;
                border-radius: 10px;
                max-width: 380px;
                height: auto;
            }
            &-icon{
                background-color: rgba(0, 0, 0, 0.6);
                padding: 5px;
                border-radius: 500px;
                position: absolute;
                top: 5px;
                right: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10px;
            }
        }
    }

}
@media screen and (min-width: 600px) {
    .MuiModal-root{
        .MuiBox-root{
            .mediaFilePreviewModal{
                img, video{
                    max-width: 500px;
                }
            }
        }
    
    } 
}
@media screen and (min-width: 720px) {
    .MuiModal-root{
        .MuiBox-root{
            .mediaFilePreviewModal{
                img, video{
                    max-width: 600px;
                }
            }
        }
    
    } 
}
@media screen and (min-width: 1200px) {
    .MuiModal-root{
        .MuiBox-root{
            .mediaFilePreviewModal{
                img, video{
                    max-width: 700px;
                }
            }
        }
    
    } 
}
@media screen and (min-width: 1500px) {
    .MuiModal-root{
        .MuiBox-root{
            .mediaFilePreviewModal{
                img, video{
                    max-width: 900px;
                }
            }
        }
    
    } 
}