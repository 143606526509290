@import './Page404/Page404';
@import './Dashboard/Dashboard';
@import './Files/Files';
@import './Magazines/Magazines';
@import './Users/Users';
@import './Permissions/Permissions';
@import './Roles/Roles';
@import './Newsletters/Newsletters';
@import './Statistics/Statistics';
@import './Settings/Settings';
@import './PageEditor/PageEditor';


.pagesStyle {
  display: flex;
  gap: 10px;
  padding-top: 10px;
  flex-direction: column;

  &-title {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 5px;
  }

  .pagesStyle-tables {
    min-height: 450px;
    margin-bottom: 15px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
  }

  .pagesStyle-tables table tr td {
    padding: 12px;
  }

  .pagesStyle-tables thead tr th {
    padding: 12px;
  }

  .no-hover:hover {
    background-color: transparent !important;
  }

  &-inputs {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    &-input {
      background-color: white;
      color: #7D879C;
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 7px;
      border-radius: 5px;

      input {
        border: none;
        max-width: 150px;
      }

      select {
        border: none;
        min-width: 187px;
        color: #7D879C;
      }

      input::placeholder {
        color: #7D879C;
        font-weight: 300;
      }

      input::-ms-input-placeholder {
        color: #7D879C;
        font-weight: 300;
      }
    }
  }

  &-tables {
    border-radius: 5px;
    padding: 10px 15px;
    background-color: #FFF;
    overflow: hidden;

    .css-1v8x7dw {
      /* Handle */
      &::-webkit-scrollbar {
        display: none;
        width: 4px;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #0C4B23;
      }
    }

    table {
      overflow: auto;
      overflow-x: scroll;
      table-layout: auto;
      width: 100%;
      min-width: 500px;

      tr {
        border-bottom: 1px solid #f5f5f5;

        th {
          font-size: 14px;
          text-align: center;
          padding: 0 30px 10px 0;
          white-space: nowrap;
          color: #707070;
          vertical-align: middle;
        }

        td {
          p {
            line-height: 20px;
          }

          input[type='checkbox'] {
            width: 15px;
            height: 15px;
            margin: 0;
          }

          color: #707070;
          font-size: 12px;
          text-align: center;
          padding: 20px 30px 20px 0;
          white-space: nowrap;
          vertical-align: middle;
        }

        .firstItem {
          text-align: left;
          padding-left: 8px;

          p {
            color: #707070;
          }
        }

        .middleItem {
          text-align: left;
        }

        .lastItem {
          text-align: right;
        }
      }

      thead > tr:first-child {
        border: none;
      }

      tbody > tr:last-child {
        border: none;

      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-mediaFiles {
    border-radius: 5px;

    &-files {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      row-gap: 20px;

      > * {
        grid-column: span 12;
      }
    }
  }

  &-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 450px) {
  .pagesStyle {
    padding: 15px 0;

    &-title {
      margin-bottom: 12px;
    }

    &-inputs {
      &-input {
        input {
          min-width: 200px;
        }

        select {
          min-width: 240px;
        }
      }
    }

    &-tables {
      padding: 12px 18px;
    }
  }
}

@media screen and (min-width: 600px) {
  .pagesStyle {
    &-inputs {
      margin-bottom: 18px;

      &-input {
        padding: 9px;
      }
    }

    &-tables {
      padding: 15px 20px;
    }

    &-mediaFiles {
      &-files {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: 15px;

        > * {
          grid-column: span 6;
        }
      }

      &-pagination {
        justify-content: end;
        padding-top: 30px;
      }
    }

    &-pagination {
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: end;
    }
  }
}

@media screen and (min-width: 900px) {
  .pagesStyle {
    padding: 20px 0;

    &-title {
      margin-bottom: 15px;
    }

    &-inputs {
      margin-bottom: 20px;
    }

    &-tables {
      padding: 18px 25px;

      table {
        tr {
          th {
            font-size: 16px;
          }

          td {
            font-size: 14px;

            input[type='checkbox'] {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }

    &-mediaFiles {
      gap: 20px;

      &-pagination {
        padding-top: 40px;
      }
    }

    &-pagination {
      padding-right: 55px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .pagesStyle {
    padding: 30px 0;

    &-title {
      font-size: 16px;
    }

    &-inputs {
      margin-bottom: 25px;

      &-input {
        padding: 11px 5px;

        input {
          min-width: 220px;
        }

        select {
          min-width: 260px;
        }
      }
    }

    &-tables {
      padding: 20px 30px;
    }

    &-mediaFiles {
      &-files {
        * {
          grid-column: span 4;
        }

        gap: 25px;
      }

      &-pagination {
        padding-top: 45px;
      }
    }

    &-pagination {
      padding-right: 60px
    }
  }
}

@media screen and (min-width: 1500px) {
  .pagesStyle {
    &-mediaFiles {
      &-files {
        * {
          grid-column: span 4;
        }

        gap: 60px;
      }

      &-pagination {
        padding-top: 45px;
      }
    }
  }
}