.dashboardMagazine{
    &-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-part2{
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
            .delete{
                font-size: 13px;
                background-color: transparent;
                color: #EA5C5F;
                display: flex;
                align-items: center;
                border: none;
                gap: 5px;
                border-radius: 5px;
                padding: 4px 8px;
                &:hover{
                    background-color: rgba(234, 92, 95, 0.05);
                }
            }
            .preview{
                text-decoration: none;
                 button{
                    font-size: 13px;
                    background-color: transparent;
                    color: #367CD2;
                    display: flex;
                    align-items: center;
                    border: none;
                    gap: 5px;
                    border-radius: 5px;
                    padding: 4px 8px;
                    &:hover{
                        background-color: rgba(54, 124, 210, 0.1);
                    }
                }
            }
        }
        
    }
    &-content{
        display: grid;
        grid-template-columns: repeat(1fr, 12);
        gap: 25px;
        .part1{
            grid-column: 1/13;
        }
        .part2{
            grid-column: 1/13;
            &-navbar{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
                .MuiTabs-root{
                    justify-content: center;
                    font-size: 16px !important;
                    min-height: 22px;
                    .MuiTabs-flexContainer{
                        justify-content: center;
                        gap: 0px;
                    }
                    .MuiTab-root{
                        color: #9A9A9A;
                        text-transform: none;
                        padding: 0 !important;
                        min-height: auto;
                        font-size: 15px;
                        min-width: 80px;
                    }
                    .Mui-selected{
                        color: #045428;
                        font-weight: 600;
                        border-bottom:2.5px solid #045428;;
                    }
                    .MuiTabs-indicator{
                        background-color: #045428;
                        height: 2.5px;
                        display: none;
                    }
                }
            } 
            &-content{
                background-color: white;
                border-radius: 5px;
                padding: 30px 20px;
            }  
        }
    }
}
@media screen and (min-width: 450px) {
    .dashboardMagazine{
        &-header{
            &-part2{
                margin-bottom: 12px;
            }
            
        }
        &-content{
            .part2{
                &-navbar{
                    .MuiTabs-root{
                        .MuiTab-root{
                            min-width: 100px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 900px) {
    .dashboardMagazine{
        &-header{
            &-part2{
                margin-bottom: 15px;
            }
            
        }
    }
}
@media screen and (min-width: 1100px) {
    .dashboardMagazine{
        &-content{
            grid-template-columns: 300px 1fr;
            gap: 20px;
            .part1{
                grid-column: span 1;
            }
            .part2{
                grid-column: span 1;
                &-navbar{
                    .MuiTabs-root{
                        .MuiTab-root{
                            min-width: 100px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1200px) {
    .dashboardMagazine{
        &-header{
            &-part2{
                margin-bottom: 20px;
                .delete{
                    font-size: 14px;
                }
                .preview{
                     button{
                        font-size: 14px;
                    }
                }
            }
            
        }
    }
}

@media screen and (min-width: 1400px) {
    .dashboardMagazine{
        &-content{
            grid-template-columns: 350px 1fr;
            gap: 30px;
        }
    }
}