.page404{
    background-image: url(../../assets/images/loginPasswordBg.png);
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
    min-height: 100vh;
    padding: 150px 0;
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    img{
        width: 250px;
        height: auto;
    }
    h3{
        color: #707070;
        font-size: 18px;
        span{
            font-weight: 500;
            color: #045428;
        }
        margin-bottom:5px;
    }
    p{
        color: #707070;
        font-size: 16px;
        margin-bottom: 20px;
    }
}
@media screen and (min-width: 960px) {
    .page404{
        min-height: 100vh;
        padding: 0;
        align-items: center;
        img{
            width: 350px;
        }
        h3{
            margin-bottom:10px;
        }
    }
}