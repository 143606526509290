.subscribe {
  background-image: url(../../assets/images/subscribeBg.png);
}
.contact {
  background-image: url(../../assets/images/contactBg.png);
}
.contactFollowLayout {
  position: relative;
  padding-top: 110px;
  background-size: cover;
  background-position: top center;
  background-position-y: 77.75px;
  background-repeat: no-repeat;
  min-height: 100vh;
  &-content {
    display: grid;
    grid-template-columns: auto auto;
    &-part1 {
      text-align: center;
      h1 {
        font-size: 23px;
        color: white;
        font-weight: 600;
        margin-bottom: 10px;
      }
      p {
        color: #b1b1b1;
        font-size: 14px;
      }
      margin-bottom: 40px;
    }
    .form {
      margin: 0 auto;
      max-width: 400px;
      padding: 15px;
      border-radius: 10px;
      height: fit-content;
    }

    .infos {
      margin-top: 100px;
      .info {
        color: white;
        display: flex;
        align-items: center;
        gap: 10px;
        h2 {
          color: #feed24;
          font-size: 18px;
          font-weight: 600;
        }
        p {
          font-size: 14px;
        }
        margin-bottom: 10px;
      }
      :last-child {
        margin-bottom: 0;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .contactFollowLayout {
    &-content {
      display: flex;
      flex-direction: column;
      padding: 70px 0 90px 0;
      &-part1 {
        text-align: start;
        h1 {
          font-size: 28px;
        }
        p {
          font-size: 18px;
        }
        margin-bottom: 40px;
        .infos {
          margin-top: 50px;
        }
      }
      &-part2 {
        display: flex;
        flex-direction: column;
        gap: 80px;
        .form {
          max-width: 450px;
          padding: 40px 20px 25px 20px;
        }
      }
    }
  }
}
@media screen and (min-width: 960px) {
  .contactFollowLayout {
    background-position-y: 0;
    background-color: #094823;
    &-content {
      padding: 70px 0 90px 0;
      min-height: 100vh;
      &-part1 {
        text-align: start;
        h1 {
          font-size: 35px;
          margin-bottom: 10px;
        }
        p {
          font-size: 16px;
        }
        margin-bottom: 70px;
      }
      .sm {
        display: none;
      }
      &-part2 {
        flex-direction: row-reverse;
        justify-content: space-between !important;
        .form {
          flex: 1;
          align-self: center;
          padding: 45px 25px 30px 25px;
        }
        .infos {
          .md {
            display: block;
          }
          flex: 1;
          align-self: center;
          .info {
            justify-content: flex-start;
            gap: 20px;
            margin-bottom: 35px;
            p {
              font-size: 16px;
            }
          }
          .infoIcon {
            p {
              padding-left: 11.5px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .contactFollowLayout {
    &-content {
      padding: 70px 0 120px 0;
      &-part1 {
        margin-bottom: 90px;
      }
      &-part2 {
        .form {
          padding: 50px 30px 35px 30px;
        }
      }
    }
  }
}
@media screen and (min-width: 1600px) {
  .contactFollowLayout {
    &-content {
      padding: 150px 0;
    }
  }
}
