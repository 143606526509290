.notifications{
    width: 300px;
    ::-webkit-scrollbar{
        width:4px;
        padding-left: 5px;
    }
    ::-webkit-scrollbar-track{
        background: rgb(255, 255, 255);
        border-radius: 500px;
    }
    ::-webkit-scrollbar-thumb{
        background: #045428;
        border-radius: 500px;
    }
    &-part1{
        padding: 5px 10px 0 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            p{
                white-space: nowrap;
                font-size: 12px;
                font-weight: 600;
            }
            span{
                border-radius: 8px;
                background-color: #FBB627;
                color: white;
                font-size: 10px;
                padding: 3px 10px 2px 10px;
            }
        }
        button{
            border: none;
            background-color: transparent;
            color: #529AEE;
            text-decoration: underline;
            white-space: nowrap;
            font-size: 10px;
            &:hover{
                color: darken(#529AEE, 10%);
            }
        }
    }
    &-part2{
        padding-bottom: 5px;
        max-height: 350px;
        overflow: scroll;
        
        .notification{
            
            padding: 10px 5px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            border-bottom: 1px solid #cccccc50;
            img{
                width: 40px;
                height: 40px;
            }
            &-content{
                display: flex;
                flex-direction: column;
                gap: 5px;
                :first-child{
                    font-size: 11px;
                    font-weight: 600;
                }
                :last-child{
                    font-size: 10px;
                    color: #707070;
                }
            }
        }
        :last-child{
            border-bottom: none;
        }
    }
}
@media screen and (min-width: 900px) {
    .notifications{
        font-size: 12px;
        &-part1{
            .title{
                p{
                    font-size: 14px;
                }
                span{
                    font-size: 12px;
                    
                }
            }
            button{
                font-size: 12px;
            }
        }
        &-part2{
            .notification{
                overflow: hidden;
                img{
                    width: 50px;
                    height: 50px;
                }
                &-content{
                    :first-child{
                        font-size: 12px;
                    }
                    :last-child{
                        font-size: 12px;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
                        white-space: pre-wrap; /* let the text wrap preserving spaces */
                    }
                }
            }
        }
    }
}