.dashboardLayout {
  &-topNavbar {
    background: #094823;
    flex: auto;
    padding: auto 0;

    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-size: 18px;
      }

      &-part2 {
        display: flex;
        align-items: center;
        gap: 15px;
        border-radius: 6px;
        overflow: hidden;
        width: 36px;
        height: 36px;
        background-color: #f3f7f4;

        &:hover {
          opacity: 90%;
        }

        a {
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
          object-position: center;
        }

        button {
          background-color: transparent;
          border: none;
          padding: 0;
          display: flex;
          align-items: center;
        }

        a {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .MuiPaper-root::-webkit-scrollbar {
    display: none !important;
  }

  .MuiPaper-root {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &-outlet {
    padding-top: 60px;
    background-color: #fafafa;
    min-height: 100vh;

    &-content {
      max-width: calc(100% - 32px);
      margin: 0 auto;
    }
  }
}
@media screen and (min-width: 600px) {
  .dashboardLayout {
    &-outlet {
      &-content {
        max-width: calc(100% - 48px);
      }
    }
  }
}

@media screen and (min-width: 900px) {
  .dashboardLayout {
    &-topNavbar {
      .title {
        margin: 15px 15px 5px;

        h2 {
          font-weight: 300;
        }
      }
    }

    &-outlet {
      padding-top: 80px;

      &-content {
        max-width: calc(100% - 78px);
      }
    }
  }
}
@media screen and (min-width: 1200px) {
    .dashboardLayout{
        &-topNavbar{
            .title{
                margin: 15px 30px 5px;
            }
        }
        &-outlet{
            &-content{
                max-width: calc(100% - 104px);
            }
        }
    }
}