.bestSwiper {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;

  &-printBtn {
    position: absolute;
    right: 0;
    padding: 10px;

    button {
      border: none;
      background-color: #628F21;
      color: white;
      padding: 8px;
      border-radius: 5px;
    }
  }

  &-pages {
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: #e3eee8;
    padding: 15px;
    overflow-x: scroll;
    width: 100%;

    ::-webkit-scrollbar-track {
      background-color: white;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background-color: #e6d700;
    }

    ::-webkit-scrollbar-thumb:hover {
      border-radius: 6px;
      background-color: #cbbd01;
    }

    .pageItem {
      background-color: white;
      border-radius: 5px;
      height: 250px;
      overflow: hidden;
      min-width: 350px;

      p {
        margin-top: 5px;
        height: 100%;
        margin-left: 15px;
        font-weight: 300;
        text-transform: capitalize;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
        -webkit-line-clamp: 2;
        overflow: hidden;

        span {
          font-size: 20px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.3);
          margin-right: 10px;
        }
      }

      .iframe-container {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 200px;

        .btn-overlay {
          outline: none;
          border: none;
          background-color: black;
          opacity: 30%;
          z-index: 2;
          cursor: pointer;
          width: 100%;
          height: 100%;
          position: relative;
        }

        .better-button {
          background-color: red;

          &:hover {
            background-color: yellow;
          }
        }
      }

      iframe {
        overflow: hidden;
        width: 100%;
        height: 200px;
        border: 0;
        position: absolute;
        top: 0;
        float: none;
        background: none;
        padding: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      iframe::-webkit-scrollbar {
        display: none;
      }

      border: 1px solid transparent;

      &:hover {
        border: 1px dashed #094823;
        cursor: pointer;
      }
    }

    .selected {
      &:hover {
        border: 1px solid #094823;
        cursor: pointer;
      }

      border: 1px solid #094823;
    }
  }

  &-navigation {
    padding: 25px 0;
    background-color: #094823;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 187.25px;

    &-btns {
      display: flex;
      align-items: center;
      gap: 0;

      &-middle {
        padding: 10px 30px;
        border-right: 1px solid rgba(255, 255, 255, 0.3);
        border-left: 1px solid rgba(255, 255, 255, 0.3);
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          font-weight: 400;
          color: white;
        }

        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        .group:hover .group-hover\:anchoring {
          font-size: 25px !important;
        }
      }

      .MuiSvgIcon-root {
        color: white;
        font-size: 30px;
        transition: transform 0.2s;

        &:hover {
          color: yellow;
          transform: scale(1.2);
        }
      }

      .disable {
        color: gray;
        transition: none;

        &:hover {
          color: gray;
          transform: none;
        }
      }
    }
  }
}
