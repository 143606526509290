@import "./ListsItems/ListItems";
@import "./MagazineLinkCard/MagazineLinkCard";
@import "./MuiPagination/muiPagination";
@import "./StatusState/StatusState";
@import "./MediaFile/MediaFileItem";
@import "./BestSwiper/BestSwiper";

.article {
    &-Tags {
        margin-top: 5px;
        display: flex;
        column-gap: 10px;
        row-gap: 5px;
        align-items: center;
        flex-wrap: wrap;
        &-tag {
            font-size: 10px;
            padding: 5px 10px;
            display: flex;
            align-items: center;
            gap: 5px;
            background-color: #edf3f0;
            color: #045428;
            border-radius: 10px;
            white-space: nowrap;
        }
    }
}
.page {
    &-quiz {
        margin-top: 5px;
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: rgba(255, 131, 44, 0.06);
        border: 1px solid #ff832c;
        border-radius: 10px;
        padding: 2px 15px;
        width: 150px;
        p {
            color: #ff832c !important;
        }
    }
    .pageListItem {
        cursor: pointer !important;
    }
}
