.magazine {
  position: relative;

  .iframe-container {
    display: flex;
  }

  .quizIcon {
    margin: auto auto 20px 20px;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 2;
    background-color: #feed24;
    padding: 15px;
    border-radius: 500px;

    img {
      width: 35px;
      height: 35px;
    }

    &:hover {
      background-color: darken(#feed24, 8%);
    }
  }
}
