.mediaFileItem{
    
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    -webkit-transition: 0.15s ease-in-out;
    -moz-transition: 0.15s ease-in-out;
    -o-transition: 0.15s ease-in-out;
    transition: 0.15s ease-in-out;
    position: relative;
    z-index: 1;
    cursor: pointer;
    &-content{
        img, video{
            border-radius: 10px 10px 0 0;
            height: 200px;
            width: 100%;
            object-fit: cover;
            object-position: center;
        }
       
    }
    &-info{
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .part1{
            p{
                font-size: 12px;
                line-height: 18px;
                color: green;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1; /* start showing ellipsis when 3rd line is reached */
                white-space: pre-wrap; /* let the text wrap preserving spaces */
                span{
                    background-color: rgba(0, 128, 0, 0.15);
                    padding: 0 5px;
                    border-radius: 10px;
                    margin-right: 3px;
                }
            }
            :first-child{
                margin-bottom: 5px;
            }
        }
    }
    &-copyIcon{
        position: absolute;
        z-index: 2;
        right: 5px;
        top: 5px;
        background-color: rgba(130, 130, 130, 0.4);
        padding: 5px 4px 4px 5px;
        border-radius: 500px;
    }
    &:hover{
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
        -webkit-transform: translateY(-1px);
        -moz-transform: translateY(-1px);
        transform: translateY(-1px);
    }
}
@media screen and (min-width: 900px) {
    .mediaFileItem{
        &-content{
            img, video{
                height: 220px;
            }
        }
    }
}
@media screen and (min-width: 1500px) {
    .mediaFileItem{
        &-content{
            img, video{
                height: 240px;
            }
        }
    }
}