.loginPasswordOrganisms{
    display: flex;
    flex-direction: column;
    &-header{
        h1,p{
            color:#4A4D4B;
        }
        h1{
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 12px;
        }  
        p{  
            font-size: 14px;
        }
        margin-bottom: 20px;
    }
    &-inputs{
        .otp-field{
            margin-bottom: 15px ;
            display: flex;
            gap: 10px;
            *{
                width: 100%;
                height: 36px;
                border-radius: 5px;
            }
            div{
                border: none;
                input{
                    border: 1px solid rgba(128, 128, 128, 0.296);
                    text-align: center;
                    font-size: 16px;
                    font-weight: 600;
                    color: #045428;
                }
                /* Chrome, Safari, Edge, Opera */
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
                }

                /* Firefox */
                input[type=number] {
                -moz-appearance: textfield;
                }
            }
        }
        .verificationCodeError{
            margin-bottom: 0;
            div{
                input{
                    border: 1px solid #D32F2F!important;
                }
                input:focus{
                    border: 2px solid #D32F2F!important;
                }
            }
        }
        .confirmationCodeErrorText{
            margin: 5px 14px 20px;
            font-size: 12px;
            color: #D32F2F;
        }
        &-input{
            margin-bottom: 15px !important;
        }
        
        :last-child{
            margin-bottom: 0 !important;
        }
    }
    &-buttons{
        font-size: 14px;
        margin-top: 20px;
        button{
            width: 100%;
            border: none;
        }
        .firstButton{
            margin-bottom: 20px;
        }
        .lastButton{
            border-top: 0.5px solid #D9D8D9;
            padding-top: 15px !important;
            background-color: white;
            text-align: start;
            color: #9A9A9A;
            a{
                text-decoration: none;
                color: #9A9A9A;
                &:hover{
                    color: #045428;
                }
            }
            &:hover{
                color: #045428;
            }
        }
    }
}
@media screen and (min-width: 450px) {
    .loginPasswordOrganisms{
        &-header{
            margin-bottom: 25px;
        }
        &-buttons{
            margin-top: 25px;
            .firstButton{
                margin-bottom: 25px;
            }
            .lastButton{
                padding-top: 15px !important;
            }
        }
    }
}
@media screen and (min-width: 600px) {
    .loginPasswordOrganisms{
        &-inputs{
            .otp-field{
                *{
                    height: 40px;
                }
            }
        }
    }
}
@media screen and (min-width: 650px) {
    .loginPasswordOrganisms{
        &-header{
            margin-bottom: 30px;
        }
        &-inputs{
            .otp-field{
                margin-bottom: 20px;
            }
            .verificationCodeError{
                margin-bottom: 0 !important;
            }
            &-input{
                margin-bottom: 20px !important;
            }
        }
        &-buttons{
            margin-top: 30px;
        }
    }
}
@media screen and (min-width: 900px) {
    .loginPasswordOrganisms{
        &-header{
            margin-bottom: 35px;
        }
        &-buttons{
            margin-top: 35px;
            .firstButton{
                margin-bottom: 30px;
            }
            .lastButton{
                padding-top: 20px !important;
            }
        }
    }
}
@media screen and (min-width: 1200px) {
    .loginPasswordOrganisms{
        &-header{
            margin-bottom: 40px;
        }
        &-inputs{
            .otp-field{
                *{
                    height: 44px;
                }
            }
        }
        &-buttons{
            margin-top: 40px;
            .firstButton{
                margin-bottom: 35px;
            }
            .lastButton{
                padding-top: 25px !important;
            }
        }
    }
}