.serverErrorStatus{
    min-height: calc(100vh - 300px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    &-content{
        border-radius: 5px;
        padding: 15px;
        border: 1px dashed darken(red, 10%);
        background-color: lighten(red, 47%);
        color: darken(red, 10%);
        h3{
            margin-top: 10px;
            font-size: 14px;
        }
    }
    
}
@media screen and (min-width: 680px) {
    .serverErrorStatus{
        min-height: calc(100vh - 100px);
        
    }
}
@media screen and (min-width: 960px) {
    .serverErrorStatus{
        &-content{
            h3{
                font-size: 15px;
            }
        }
    }
}