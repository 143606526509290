footer {
  background: #094823;
  .footerDesktopView {
    display: none;
  }
  .footerMobileView {
    padding: 15px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .icon {
      width: 80px;
      height: auto;
      margin-bottom: 15px;
    }
    .goal {
      text-align: center;
      font-size: 13px;
      font-weight: 300;
      margin-bottom: 10px;
    }
    .links {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      a {
        margin-right: 5px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.775);
        text-decoration: none;
        &:hover {
          color: #feed24;
          cursor: pointer;
        }
      }
      .active {
        color: #feed24;
        border-bottom: 1.5px solid #feed24;
      }
      margin-bottom: 10px;
      button {
        color: rgba(255, 255, 255, 0.775);
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.775);
        padding: 1px 10px;
        border-radius: 10px;
        &:hover {
          color: white;
          border: 1px solid white;
        }
      }
    }
    .copyright {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 30px;
      p {
        font-size: 12px;
      }
      &-icons {
        display: flex;
        align-items: center;
        gap: 7px;
        button {
          background-color: transparent;
          box-shadow: none;
          border: none;
          color: white;
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: center;
        }
        button:hover {
          cursor: pointer;
        }
      }
    }
  }
}
@media screen and (min-width: 960px) {
  footer {
    .footerMobileView {
      display: none;
    }
    .footerDesktopView {
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px;
      .icon {
        margin-bottom: 70px;
        button {
          cursor: pointer;
          border: 1px solid black;
        }
      }
      &-part1 {
        font-size: 12px;
        img {
          width: 80px;
          height: auto;
          margin-bottom: 10px;
        }
        .goal {
          margin-bottom: 50px;
        }
      }
      &-part2 {
        align-self: flex-end;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .links {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          row-gap: 20px;
          column-gap: 20px;
          a {
            grid-column: span 1;
            color: rgba(255, 255, 255, 0.775);
            font-size: 12px;
            &:hover {
              color: #feed24;
              cursor: pointer;
            }
            text-decoration: none;
          }
          .active {
            color: #feed24;
          }
          margin-bottom: 50px;
        }
        .icons {
          text-align: end;
          display: flex;
          gap: 15px;
          button {
            background-color: transparent;
            box-shadow: none;
            border: none;
            color: white;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
          }
          button:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
