.MuiModal-root{
    .MuiBox-root{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .mediaFilesModal{
            position: relative;
            background-color: white;
            border-radius: 10px;
            position: relative;
            width: calc(100vw - 30px);
            height: calc( 100vh - 100px);
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            &-header{
                background-color: white;
                position: sticky;
                z-index: 2;
                top: 0;
                right: 0;
                padding: 10px;
                &-title{
                    padding-bottom: 15px;
                    font-weight: 300;
                    font-size: 14px;
                }
                &-inputs{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 10px;
                }
            }
            &-content{
                padding: 10px;
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                >*{
                    grid-column: span 12;
                }
                gap: 15px;
            }
            &-pagination{
                display: flex;
                align-items: center;
                justify-content: end;
                padding: 10px;
            } 
        }
    }

}
@media screen and (min-width: 600px) {
    .MuiModal-root{
        .MuiBox-root{
            .mediaFilesModal{
                width: calc(100vw - 40px);
                &-header{
                    padding: 15px;
                    &-title{
                        padding-bottom: 15px;
                    }
                }
                &-content{
                    padding:0 15px 15px 15px;
                    >*{
                        grid-column: span 6;
                    }
                    gap: 15px;
                    
                }
                &-pagination{
                    padding: 15px;
                } 
            }
        }
    
    } 
}
@media screen and (min-width: 720px) {
    .MuiModal-root{
        .MuiBox-root{
            .mediaFilesModal{
                width: 680px;
            }
        }
    } 
}
@media screen and (min-width: 1200px) {
    .MuiModal-root{
        .MuiBox-root{
            .mediaFilesModal{
                width: 900px;
                height: unset;
                min-height: auto;
                max-height: calc(100vh - 200px);
                &-header{
                    padding: 20px;
                    &-title{
                        padding-bottom: 20px;
                        font-size: 16px;
                    }
                }
                &-content{
                    padding: 0 20px 20px 20px;
                    >*{
                        grid-column: span 4;
                    }
                    gap: 15px;
                }
                &-pagination{
                    padding: 20px;
                } 
            }
        }
    
    } 
}
@media screen and (min-width: 1500px) {
    .MuiModal-root{
        .MuiBox-root{
            .mediaFilesModal{
                width: 1000px;
                &-content{
                    gap: 20px;
                }
            }
        }
    } 
}