.noResultsFound{
    min-height: calc(100vh - 200px);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    img{
        width: 70px;
        height: auto;
        margin-bottom: 15px;
    }
    h3{
        color: #707070;
        font-size: 18px;
        span{
            font-weight: 500;
            color: #045428;
        }
        margin-bottom:5px;
    }
    .commingSoon{
        font-size: 40px;
        color: #045428;
        font-weight: 600;
        span{
            color:#D2DFD8;
        }
    }
}
@media screen and (min-width: 680px) {
    .noResultsFound{
        min-height: calc(100vh - 250px);
    }
}
@media screen and (min-width: 960px) {
    .noResultsFound{
        min-height: calc(100vh - 150px);
        img{
            width: 80px;
        }
    }
}