.settings {
  &-content {
    > * {
      margin-bottom: 15px;
      border-radius: 5px;
    }
  }
}

.settingsOrganismsStyle {
  background-color: white;
  padding: 20px 15px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15);

  input[type="file"] {
    display: none;
  }

  &-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .profilDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin: 25px 0 20px 0;

    .profilPicInput {
      display: flex;
      margin-bottom: 15px;


      &-style {
        width: 120px;
        height: 120px;
        position: relative;

        img {
          border-radius: 10px;
          width: 120px;
          height: 120px;
        }

        .icon {
          position: absolute;
          top: -12px;
          right: -12px;
          svg:hover {
            background-color: red;
          }

          svg:active {
            background-color: #cc0000;
          }
        }
      }

      &-noPhoto {
        width: 120px;
        height: 120px;
        overflow: hidden;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border-radius: 10px;

        &:hover {
          opacity: 80%;
        }
        img {
          width: 120px;
          height: 120px;
        }
      }

      &-allowedType {
        margin-top: 5px;
        color: #9D9D9D;
        font-size: 10px;
        text-align: center;
      }

      &-error {
        color: rgb(220, 0, 0);
        font-size: 10px;
        text-align: center;
      }
    }
  }

  &-inputs {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 15px;

    .input {
      grid-column: span 12;
    }

    .warning {
      border: 1px dashed #F6C944;
      grid-column: span 12;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 5px 10px;
      background-color: #FEF8E0;
      border-radius: 5px;

      &-content {
        grid-column: span 12;
        font-size: 14px;

        h4 {
          margin-bottom: 5px;
          font-weight: 500;
        }

        p {
          color: #646464;
        }
      }
    }

    .deactivateAccountInput {
      display: flex;
      align-items: center;
      grid-column: span 12;
      color: #4A4D4B;
      font-size: 12px;

      input {
        margin-right: 5px;
      }

      label {
        vertical-align: middle !important;
      }
    }
  }

  &-buttons {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  &-status {
    margin-top: 20px;
  }
}

@media screen and (min-width: 600px) {
  .settings {
    &-content {
      > * {
        margin-bottom: 20px;
      }
    }
  }
  .settingsOrganismsStyle {
    padding: 20px;

    &-title {
      margin-bottom: 20px;
    }

    .profilDetails {
      margin: 25px 0;

      .profilPicInput {
        margin-bottom: 15px;
      }
    }

    &-inputs {
      gap: 10px;

      .input {
        grid-column: span 6;
      }

      .warning {
        padding: 7px 12px;

        &-content {
          h4 {
            margin-bottom: 7px;
          }
        }
      }
    }

    &-buttons {
      margin-top: 25px;
      justify-content: flex-end;
    }

    &-status {
      margin-top: 25px;
    }
  }
}

@media screen and (min-width: 900px) {
  .settings {
    &-content {
      > * {
        margin-bottom: 25px;
      }
    }
  }
  .settingsOrganismsStyle {
    &-title {
      margin-bottom: 22px;
    }

    .profilDetails {
      margin: 28px 0;
    }

    &-inputs {
      gap: 15px;

      .input {
        grid-column: span 6;
      }

      .warning {
        gap: 10px;
        padding: 10px 15px;
      }
    }

    &-buttons {
      margin-top: 28px;
    }

    &-status {
      margin-top: 28px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .settings {
    &-content {
      > * {
        margin-bottom: 30px;
      }
    }
  }
  .settingsOrganismsStyle {
    padding: 20px 25px;

    &-title {
      margin-bottom: 25px;
    }

    .profilDetails {
      margin: 30px 0;
      align-items: flex-start;
    }

    &-inputs {
      gap: 20px;

      .input {
        grid-column: span 4;
      }

      .warning {
        gap: 15px;
        padding: 15px 20px;

        &-content {
          h4 {
            margin-bottom: 8px;
          }
        }
      }
    }

    &-buttons {
      margin-top: 30px;
    }

    &-status {
      margin-top: 30px;
    }
  }
}
