header {
  position: absolute;
  z-index: 2;

  .headerMobileView {
    background-color: #045428;

    &-content {
      &-part1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;

        .icon {
          width: 55px;
          height: auto;
        }
      }

      &-part2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding-bottom: 30px;

        a {
          font-size: 14px;
          color: white;
          text-decoration: none;

          &:hover {
            color: #a4c408;
          }
        }

        .active {
          color: #a4c408;
          border-bottom: 2px solid #a4c408;
        }
      }

      .hideshowDownMenu {
        display: none;
      }
    }

    .input {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2px;
      padding: 2px;
      border-radius: 6px;
      border: 0.5px solid rgba(255, 255, 255, 0.2);
      background-color: #04542887;

      input {
        font-size: 14px;
        background-color: transparent;
        border: none;
        color: white;
        padding: 4px 4px 4px 10px;
        width: 100%;

        ::placeholder {
          color: white;
        }
      }

      .search-btn {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      ::placeholder {
        color: #ffffff7e;
      }
    }
  }

  .headerDestopView {
    display: none;

    .login-link {
      color: white;
      margin: 0;

      .login-label {
        display: none;

        &:hover {
          display: block;
        }
      }

      svg:hover {
        opacity: 80%;
      }
    }
  }
}

@media screen and (min-width: 960px) {
  header {
    .headerMobileView {
      display: none;
    }

    .headerDestopView {
      display: block;
      background-color: transparent;

      .login-link {
        margin: 0;
        background-color: rgba(238, 238, 238, 0.3);
        transition: 300ms;
        display: flex;
        justify-content: space-between;

        &:hover {
          width: 100px;
        }

        .login-btn-icon {
          z-index: 5;
          background-color: red;
        }

        .login-label {
          width: 0;
          color: transparent;
          display: block;
          transition: 300ms;

          &:hover {
            color: white;
            width: 100%;
          }
        }

        svg:hover {
          opacity: 80%;
        }
      }

      &-content {
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon {
          width: 105px;
          height: auto;
        }

        &-links {
          display: flex;
          align-items: center;
          gap: 25px;

          a {
            font-size: 14px;
            color: white;
            text-decoration: none;
            padding: 0 5px 2px 5px;
            -webkit-transition: 0.15s ease-in-out;
            -moz-transition: 0.15s ease-in-out;
            -o-transition: 0.15s ease-in-out;
            transition: 0.15s ease-in-out;

            &:hover {
              color: #a4c408;
              -webkit-transform: translateY(-1px);
              -moz-transform: translateY(-1px);
              transform: translateY(-1px);
            }
          }

          .active {
            color: #a4c408;
            font-weight: 500;
            border-bottom: 2px solid #a4c408;
          }
        }

        .input {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 2px;
          overflow: hidden;
          border-radius: 6px;
          border: 0.5px solid rgba(255, 255, 255, 0.2);
          background-color: #04542887;

          input {
            background-color: transparent;
            border: none;
            color: white;
            font-size: 14px;
            transition: all 300ms ease-in-out;
            animation-duration: 600ms;
          }

          .non-hidden {
            width: 150px;
            padding: 2px 8px 2px 8px;
          }

          .hidden {
            width: 0;
            padding: 0;
          }

          .child-btn {
            display: none;
          }

          .parent-btn:hover .child-btn {
            display: block;
          }

          .search-btn {
            padding: 6px 8px 6px 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            .search-btn-icon {
              z-index: 5;
              background-color: red;
            }
          }

          ::placeholder {
            color: #ffffff7e;
          }
        }
      }
    }
  }
}
