.popover{
    position: absolute;
    font-size: 14px;
    right: 50%;
    padding: 5px 25px;
    border-radius: 5px 0 5px 5px;
    white-space: nowrap;
    color: white;
    background-color: rgb(141, 141, 141);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}