.statusState{
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 13.3px;
    p{
        text-align: center;
    }
    &-error{
        border: 1px dashed darken(red, 10%);
        background-color: lighten(red, 47%);
        color: darken(red, 10%);
        margin-bottom: 5px;
    }
    &-success{
        border: 1px dashed darken(green, 10%);
        background-color: rgb(229, 248, 229);
        color: darken(green, 10%);
    }
    &-loading{
        border: 1px dashed darken(blue, 15%);
        background-color: rgb(244, 244, 254);
        color: darken(blue, 15%);
    }
}
@media screen and (min-width: 600px) {
    .statusState{
        padding: 7px 10px;
    } 
 }
@media screen and (min-width: 1200px) {
    .statusState{
        padding: 9px 10px;
    } 
 }